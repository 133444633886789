import React from "react"
import styled from "styled-components"
import Template from "../components/templates/Template"
import { Helmet } from "react-helmet"
import Error from "@icons/error.svg"
import { H2 } from "../components/styled/headers"
import { Button } from "../components/common/Button"
import { useBasicQuery } from "@useBasicQuery"

const Wrapper = styled.div`
    text-align: center;

    max-width: 600px;
    margin: 0 auto 50px;
`

const IconWrapper = styled.div`
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
`

const Text = styled.div`
    margin: 30px 0;
`

const NotFound = () => {
    const {
        page404: {
            acf: { header, text, buttonText, link },
        },
    } = useBasicQuery()
    return (
        <Template>
            <Helmet
                title="404"
                meta={[
                    {
                        name: `description`,
                        content: "404",
                    },
                ]}
            />
            <Wrapper>
                <IconWrapper>
                    <Error />
                </IconWrapper>
                <H2
                    dangerouslySetInnerHTML={{
                        __html: header,
                    }}
                />
                <Text
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
                {link && buttonText && (
                    <Button isLink href={link}>
                        {buttonText}
                    </Button>
                )}
            </Wrapper>
        </Template>
    )
}

export default NotFound
